











































import { Component, Mixins, Vue } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image, Button } from "vant";
import { isWeiBo } from "@/utils/index";

@Component({
  components: {
    [Image.name]: Image,
    [Button.name]: Button,
  },
})
export default class DownloadApp extends Mixins(Mixin) {
  isLoading = false;
  mark = false;
  created(): void {
    this.isLoading = false;
  }
  mounted(): void {
    if (Vue.prototype.$AppSource === "ios" && isWeiBo()) {
      this.mark = true;
    }
  }
  downloadApp(): void {
    this.isLoading = true;
    window.location.href =
      "https://a.app.qq.com/o/simple.jsp?pkgname=com.poly.mobile&android_scheme=polyt://&ios_scheme=polyt://";
  }
}
